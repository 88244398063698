
var tenantName = "pandaequipforequality.onmicrosoft.com";
var ADB2C_URL="https://pandaequipforequality.b2clogin.com"
var signInSignUpPolicyName = "b2c_1_susi";
var resetPasswordPolicyName = "b2c_1_reset";
var redirect_uri = location.href.replace(location.hash, '');

var helloJsSignInSignUpPolicy = "b2c_sisu";
var helloJsResetPasswordPolicy = "b2c_reset";

/*
 * B2C Sign-In and Sign-Up Policy Configuration
 */
(function (hello) {
   "use strict";
   hello.init({
      b2c_sisu: {
         name: 'Azure Active Directory B2C',
         oauth: {
            version: 2,
            auth:ADB2C_URL + "/" + tenantName + "/" + signInSignUpPolicyName + "/oauth2/v2.0/authorize",
            grant:ADB2C_URL + "/" + tenantName + "/" + signInSignUpPolicyName + "/oauth2/v2.0/token"
         },
         refresh: true,
         scope_delim: ' ',
         logout: function () {
            //get id_token from auth response
            var id_token = hello(helloJsSignInSignUpPolicy).getAuthResponse().id_token;
            //clearing local storage session
            hello.utils.store(helloJsSignInSignUpPolicy, null);

            //redirecting to Azure B2C logout URI
            window.location =ADB2C_URL + "/" + tenantName + "/oauth2/v2.0/logout?p=" + signInSignUpPolicyName + "&id_token_hint=" +
               id_token + "&post_logout_redirect_uri=" + redirect_uri;
         }
      }
   });
})(hello);

/*
 * B2C Reset Password Policy Configuration
 */
(function (hello) {
   "use strict";
   hello.init({
      b2c_reset: {
         name: 'Azure Active Directory B2C',
         oauth: {
            version: 2,
            auth: ADB2C_URL + "/" + tenantName + "/" + resetPasswordPolicyName + "/oauth2/v2.0/authorize",
            grant:ADB2C_URL + "/" + tenantName + "/" + resetPasswordPolicyName + "/oauth2/v2.0/token"
         },
         refresh: false,
         scope_delim: ' ',
         logout: function (p) {
            //get id_token from auth response
            var id_token = hello(helloJsResetPasswordPolicy).getAuthResponse().id_token;
            //clearing local storage session
            hello.utils.store(helloJsResetPasswordPolicy, null);
         }
      }
   });
})(hello);
